import { AppModule } from 'app/module';
import { WatchlistButton } from 'module/watchlistButton/main';
import { MasterLayout } from 'layout/master/main';
import { user } from 'app/user';
import { userWatchlist } from 'app/userWatchlist';
import Template from './template.hbs';
import './styles.scss';

export class JobCardMini extends AppModule {
    setTemplate() {
        this.template = Template;
        if (module.hot) {
            module.hot.accept('./template.hbs', () => {
                this.template = Template;
                this.render();
            });
        }
    }

    domBindings() {
        return {
            watchlist: '.m-jobCardMini__watchlist .m-watchlistButton',
            pushedJob: '.m-jobCardMini__labelPushedJob',
            bsLabel: '.m-jobCardMini__bsLabel',
            brandingSolutionPill: '.m-jobCardMini__bsLabel--darkGreen',
            logo: '.m-jobCardMini__logo',
            titleLink: '.m-jobCardMini__titleLink',
            companyLink: '.m-jobCardMini__companyLink',
            date: '.m-jobCardMini__date',
        };
    }

    subEvents() {
        if (!user.isLoggedIn()) {
            userWatchlist.events.on('onUnauthorized', (watchlistObject) => {
                this.layout.openLoginModal(watchlistObject.arguments[0].watchlistObject.state, true, true);
            });
        }
    }

    subs() {
        this.layout = new MasterLayout(document.documentElement);

        if (this.dom.watchlist) {
            this.watchlistButton = new WatchlistButton(this.dom.watchlist);
        }
    }

    subsProps() {
        if (this.watchlistButton) {
            this.watchlistButton.updateProps({ id: this.props.id });
        }
    }

    getPropsFromDom() {
        return {
            id: this.dom.el.getAttribute('data-id'),
            loading: this.dom.el.getAttribute('data-isloading'),
            title: this.dom.titleLink?.innerHTML,
            pushedJob: !!this.dom.pushedJob,
            date: this.dom.date?.innerHTML,
            company: {
                slug: this.dom.companyLink?.getAttribute('data-slug'),
                name: this.dom.companyLink?.innerText,
                link: this.dom.companyLink?.href,
                hasBranding: this.dom.bsLabel !== null,
                isBrandingSolution: !!this.dom.brandingSolutionPill,
                logo: this.dom.logo?.getAttribute('alt') ? this.dom.logo.src : false,
            },
        };
    }
}

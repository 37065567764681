import { AppModule } from 'app/module';
import { app } from 'app/app';
import { mediaQueries } from 'util/mediaQueries';
import { JobsFilterDropdown } from 'module/jobsFilterDropdown/main';
import { Checkbox } from 'module/checkbox/main';
import Template from './template.hbs';
import './styles.scss';

let openDropdownIndex = false;
const expireTime = new Date();
expireTime.setDate(expireTime.getDate() + 7);

export class JobsFilter extends AppModule {
    setTemplate() {
        this.template = Template;
        if (module.hot) {
            module.hot.accept('./template.hbs', () => {
                this.template = Template;
                this.render();
            });
        }
    }

    ready() {
        app.events.on('firstUserInteraction', () => this.loadFilterMarkup());
    }

    getSerializedItems() {
        let serializedItems = {};
        for (let i = 0; i < this.jobsFilterDropdown.length; i += 1) {
            serializedItems = { ...this.jobsFilterDropdown[i].getSerializedItems(), ...serializedItems };
        }
        for (let i = 0; i < this.checkboxFilters.length; i += 1) {
            const formData = {};
            formData[this.checkboxFilters[i].props.name] = 0;
            if (this.checkboxFilters[i].props.checked) {
                formData[this.checkboxFilters[i].props.name] = this.checkboxFilters[i].props.value;
            }
            serializedItems = { ...formData, ...serializedItems };
        }
        return serializedItems;
    }

    domBindings() {
        this.dom.inner = this.dom.el.querySelector('.m-jobsFilter__inner');
        this.dom.toggleFilterTrigger = this.dom.el.querySelector('.m-jobsFilter__toggleFilterTrigger');
        this.dom.jobsFilterDropdown = this.dom.el.querySelectorAll('.m-jobsFilterDropdown');
        this.dom.group = this.dom.el.querySelectorAll('.m-jobsFilter__groups');
        this.dom.checkboxFilters = this.dom.el.querySelectorAll('.m-jobsFilter__checkboxFilter .m-checkbox');
        this.dom.shadowStart = this.dom.el.querySelector('.m-jobsFilter__shadow--start');
        this.dom.shadowEnd = this.dom.el.querySelector('.m-jobsFilter__shadow--end');

        this.jobsFilterDropdown = [];
        [].forEach.call(this.dom.jobsFilterDropdown, ($item) => {
            this.jobsFilterDropdown.push(new JobsFilterDropdown($item));
        });

        if (this.dom.checkboxFilters) {
            this.checkboxFilters = [];
            for (let i = 0; i < this.dom.checkboxFilters.length; i += 1) {
                this.checkboxFilters[i] = new Checkbox(this.dom.checkboxFilters[i]);
            }
        }
    }

    getPropsFromDom() {
        const items = this.getFilterProps();
        const count = this.getAllFilterDropDownCount(items);
        return {
            responsiveLayout: this.dom.el.classList.contains('m-jobsFilter--responsiveLayout'),
            open: false,
            count,
            items,
        };
    }

    domEvents() {
        this.dom.inner.addEventListener('touchstart', (e) => {
            e.stopPropagation();
        });
        this.dom.inner.addEventListener('touchmove', (e) => {
            e.stopPropagation();
        });
        if (this.dom.toggleFilterTrigger) {
            this.dom.toggleFilterTrigger.addEventListener('click', () => this.toggleFilter());
        }
        this.dom.inner.addEventListener('scroll', () => this.handleScroll());
    }

    subEvents() {
        mediaQueries.events.on('changed', () => {
            if (!mediaQueries.isMobile()) {
                this.toggleFilter(false);
            }
        });
        window.addEventListener('resize', () => this.handleShadowVisibility());

        for (let i = 0; i < this.jobsFilterDropdown.length; i += 1) {
            this.propagateEvents(this.jobsFilterDropdown[i], 'openLoginModal');

            this.jobsFilterDropdown[i].events.on('closeDropdownsClick', (e) => {
                this.closeAllJobsSearchformDropdowns(e.arguments[0]);
            });

            this.jobsFilterDropdown[i].events.on('updateFilterProps', (accordionProps) => {
                this.updateFilterProps(accordionProps.arguments[0], accordionProps.arguments[1]);
            });

            // eslint-disable-next-line no-loop-func
            this.jobsFilterDropdown[i].events.on('openOnFirstUserInteraction', () => {
                openDropdownIndex = i;
            });
        }

        for (let i = 0; i < this.checkboxFilters.length; i += 1) {
            this.checkboxFilters[i].events.on('toggle', () => {
                this.updateFilterProps(false, true);
            });
        }
    }

    updateFilterProps(accordionProps = false, updateJobCount = false) {
        const items = this.getFilterProps();
        const count = this.getAllFilterDropDownCount(items);
        this.updateProps(
            {
                items,
                count,
            },
            false,
        );

        if (!mediaQueries.isMobile()) {
            if (accordionProps) {
                if (!this.dom.inner.classList.contains('m-jobsFilter__inner--open')) {
                    this.dom.inner.classList.add('m-jobsFilter__inner--open');
                }
            } else {
                this.dom.inner.classList.remove('m-jobsFilter__inner--open');
            }
        }

        if (updateJobCount) {
            this.loadFilterMarkup(true);
        }
    }

    loadFilterMarkup(withUserInput = false) {
        this.events.emit('loadFilterMarkup', withUserInput);
    }

    onFilterMarkupResponse(response) {
        const newItems = [];

        for (let i = 0; i < response.filter.items.length; i += 1) {
            newItems.push({
                open: this.props.items[i] ? this.props.items[i].open : false,
                ...this.props.items[i],
                ...response.filter.items[i],
            });
        }

        this.updateProps({
            items: newItems,
            count: response.filter.count,
        }, true);
    }

    afterRender() {
        this.handleShadowVisibility();
        if (openDropdownIndex !== false) {
            const tmpIndex = openDropdownIndex;
            openDropdownIndex = false;
            this.jobsFilterDropdown[tmpIndex].changedAccordion(false);
        }
    }

    handleScroll() {
        const { scrollLeft } = this.dom.inner;

        for (let i = 0; i < this.jobsFilterDropdown.length; i += 1) {
            this.jobsFilterDropdown[i].updateProps({ scrollLeft });
        }

        this.handleShadowVisibility();
    }

    handleShadowVisibility() {
        const maxScrollStartReached = this.dom.inner.scrollLeft <= 0;
        // eslint-disable-next-line max-len
        const maxScrollEndReached = this.dom.inner.scrollLeft >= this.dom.inner.scrollWidth - this.dom.inner.offsetWidth;

        this.toggleShadow(this.dom.shadowStart, maxScrollStartReached);
        this.toggleShadow(this.dom.shadowEnd, maxScrollEndReached);
    }

    toggleShadow($el, maxScrollReached) {
        const shadowIsVisible = $el.classList.contains('m-jobsFilter__shadow--visible');
        const showShadow = !maxScrollReached && !shadowIsVisible;
        const hideShadow = maxScrollReached && shadowIsVisible;

        // Using requestAnimationFrame for optimal scroll performance.
        // https://stackoverflow.com/a/44779316
        if (showShadow) {
            window.requestAnimationFrame(() => $el.classList.add('m-jobsFilter__shadow--visible'));
        } else if (hideShadow) {
            window.requestAnimationFrame(() => $el.classList.remove('m-jobsFilter__shadow--visible'));
        }
    }

    toggleFilter(open = !this.props.open) {
        this.updateProps({ open }, true);
    }

    getFilterProps() {
        let i;
        const filterProps = {};
        for (i = 0; i < this.jobsFilterDropdown.length; i += 1) {
            filterProps[i] = this.jobsFilterDropdown[i].getPropsFromDom();
        }
        for (let y = 0; y < this.checkboxFilters.length; y += 1) {
            filterProps[i] = { checkbox: true, ...this.checkboxFilters[y].getPropsFromDom() };
        }
        return filterProps;
    }

    getAllFilterDropDownCount(items) {
        let count = 0;

        Object.keys(items).forEach((index) => {
            if (items[index].count) {
                count += parseInt(items[index].count, 10);
            } else if (items[index].checkbox && items[index].checked) {
                count += 1;
            }
        });

        return count;
    }

    closeAllJobsSearchformDropdowns(isClickedOutside) {
        for (let i = 0; i < this.jobsFilterDropdown.length; i += 1) {
            this.jobsFilterDropdown[i].updateProps({ open: false }, true);
        }

        if (!isClickedOutside) {
            this.events.emit('closeProximityDropdown');
        }
    }

    hasFilterMarkup() {
        return this.props.items && Object.keys(this.props.items[0]?.items ?? {}).length !== 0;
    }

    hasActiveFilter() {
        const props = this.getFilterProps();
        let active = false;
        Object.values(props).forEach((prop) => {
            if (prop.radio) {
                if (prop.items['0'].checked === false) {
                    active = true;
                }
            } else if (prop.checkbox) {
                if (prop.checked === true) {
                    active = true;
                }
            } else {
                Object.values(prop.items).forEach((item) => {
                    if (item.checked) {
                        active = true;
                    }
                });
            }
        });
        return active;
    }
}

import { AppModule } from 'app/module';
import { Input } from 'module/input/main';
import { serializeJson } from 'util/form';
import { Autocomplete } from 'module/autocomplete/main';
import { JobsFilter } from 'module/jobsFilter/main';
import { Dropdown } from 'module/dropdown/main';
import {
    API_ENDPOINT_AUTOCOMPLETE_KEYWORDS,
    API_ENDPOINT_AUTOCOMPLETE_LOCATIONS,
    API_ENDPOINT_PROXIMITY_LOCATION,
    API_ENDPOINT_FILTERS,
} from 'app/endpoints';
import { getJson } from 'util/xhr';
import { params as urlWithParams } from 'util/url';
import { JobSearchHistory } from 'util/client-side-storage/jobSearchHistory';
import 'module/button/main';
import Template from './template.hbs';
import './styles.scss';

const resetSalaryCountId = '10006';

export class JobsSearchform extends AppModule {
    setTemplate() {
        this.template = Template;
        if (module.hot) {
            module.hot.accept('./template.hbs', () => {
                this.template = Template;
                this.render();
            });
        }
    }

    domBindings() {
        return {
            keywords: '.m-jobsSearchform__keywords .m-input',
            keywordsAutocomplete: '.m-jobsSearchform__keywordsAutocomplete .m-autocomplete',
            locations: '.m-jobsSearchform__locations .m-input',
            locationsAutocomplete: '.m-jobsSearchform__locationsAutocomplete .m-autocomplete',
            proximityDropdown: '.m-jobsSearchform__proximity .m-dropdown',
            submitButton: '.m-jobsSearchform__submit',
            jobsFilter: '.m-jobsFilter',
            collapsedSearchBar: '.m-jobsSearchform__collapsedSearchBar',
            collapsedSearchBarInput: '.m-jobsSearchform__collapsedSearchBar input',
            keywordsContainer: '.m-jobsSearchform__keywords',
            proximityContainer: '.m-jobsSearchform__proximityContainer',
            filterContainer: '.m-jobsSearchform__filter',
            submitContainer: '.m-jobsSearchform__submitGroup',
        };
    }

    getSerializedProps() {
        if (this.jobsFilter) {
            const filterProps = this.jobsFilter.getSerializedItems();
            if (Object.keys(filterProps).length > 0) {
                return { ...filterProps, ...this.props };
            }
        }

        return this.props;
    }

    getPropsFromDom() {
        return {
            proximity: this.proximityDropdown.getPropsFromDom(),
            collapsedSearchform: Boolean(this.dom.collapsedSearchBar),
            searchbarText: this.dom.collapsedSearchBarInput?.value,
            ...serializeJson(this.dom.el),
        };
    }

    subs() {
        if (this.dom.keywords && this.dom.keywordsAutocomplete) {
            this.keywords = new Input(this.dom.keywords);
            this.keywordsAutocomplete = new Autocomplete(this.dom.keywordsAutocomplete);
            this.keywordsAutocomplete.set({
                input: this.keywords,
                endpoint: API_ENDPOINT_AUTOCOMPLETE_KEYWORDS,
                cache: () => JobSearchHistory.getKeywordArray(),
                removeFromCache: async (keyword) => JobSearchHistory.removeKeywordFromHistory(keyword),
            });
        }
        if (this.dom.locations) {
            this.locations = new Input(this.dom.locations);
            this.locationsAutocomplete = new Autocomplete(this.dom.locationsAutocomplete);
            this.locationsAutocomplete.set({
                input: this.locations,
                endpoint: API_ENDPOINT_AUTOCOMPLETE_LOCATIONS,
                cache: () => JobSearchHistory.getLocationArray(),
                removeFromCache: async (location) => JobSearchHistory.removeLocationFromHistory(location),
            });
        }
        if (this.dom.proximityDropdown) {
            this.proximityDropdown = new Dropdown(this.dom.proximityDropdown);
            this.proximityDropdown.events.on('closeAllDropdowns', () => this.closeAllDropdowns());
        }
        if (this.dom.jobsFilter) {
            this.jobsFilter = new JobsFilter(this.dom.jobsFilter);
        }
        if (this.dom.collapsedSearchBar) {
            this.collapsedSearchBar = new Input(this.dom.collapsedSearchBar);
        }
    }

    domEvents() {
        this.dom.el.addEventListener('submit', (e) => this.submitForm(e));

        if (this.dom.collapsedSearchBar) {
            this.dom.collapsedSearchBar.addEventListener('click', () => {
                this.unfoldForm();
            });
        }
    }

    submitForm(e) {
        if (e) e.preventDefault();

        if (this.jobsFilter) {
            this.jobsFilter.updateProps({ open: false }, true);
        }

        const hasActiveAutocomplete = this.hasActiveAutocomplete();

        this.keywordsAutocomplete.insertSelectedItem();
        if (this.locationsAutocomplete) this.locationsAutocomplete.insertSelectedItem();

        if (hasActiveAutocomplete) return;

        let newProps = {};
        if (this.dom.submitButton.classList.contains('m-jobsSearchform__submit--index')) {
            const json = serializeJson(this.dom.el);
            json.focusResults = true;
            this.events.emit('jobsSearchformIndexSubmit', json);
        } else {
            if (!this.jobsFilter.hasFilterMarkup()) {
                newProps = Object.assign(
                    this.getSerializedProps(),
                    // always send empty slugs from search form
                    {
                        keywordSlug: false,
                        locationSlug: false,
                    },
                );
            } else {
                newProps = Object.assign(
                    serializeJson(this.dom.el),
                    // always send empty slugs from search form
                    {
                        keywordSlug: false,
                        locationSlug: false,
                    },
                );
            }
            if (newProps['salary[]']?.[0] === resetSalaryCountId) newProps['salary[]'] = [];
            this.events.emit('jobsSearchformSubmit', newProps);
        }
    }

    // When a logged-out user wants to select a salary, the previously selected filters
    // should be sent to the redirectUrl
    getUserJobSearchRequest() {
        let newProps = {};
        if (!this.jobsFilter.hasFilterMarkup()) {
            newProps = Object.assign(
                this.getSerializedProps(),
                // always send empty slugs from search form
                {
                    keywordSlug: false,
                    locationSlug: false,
                },
            );
        } else {
            newProps = Object.assign(
                serializeJson(this.dom.el),
                // always send empty slugs from search form
                {
                    keywordSlug: false,
                    locationSlug: false,
                },
            );
        }
        return newProps;
    }

    closeAllDropdowns() {
        if (this.proximityDropdown) this.proximityDropdown.closeDropdown();
    }

    hasActiveAutocomplete() {
        const keywordsAutocompleteProps = this.keywordsAutocomplete.getProps();
        const locationsAutocompleteProps = this.locationsAutocomplete ? this.locationsAutocomplete.getProps() : false;

        return !!((keywordsAutocompleteProps && keywordsAutocompleteProps.selectedIndex)
            || (locationsAutocompleteProps && locationsAutocompleteProps.selectedIndex));
    }

    subEvents() {
        if (this.locationsAutocomplete) {
            this.locationsAutocomplete.events.on('changed', (e) => this.checkProximityStatus(e));
            this.locationsAutocomplete.events.on('hide', () => this.resetProximityDropdown());
        }
        if (this.locations) {
            this.locations.events.on('actionIconClicked', (e) => this.resetSearchInput(e, this.locations));
            this.locations.events.on('blur', () => {
                if (this.jobsFilter) this.loadFilterMarkup(true);
            });
        }
        if (this.keywords) {
            this.keywords.events.on('actionIconClicked', (e) => this.resetSearchInput(e, this.keywords));
            this.keywords.events.on('blur', () => {
                if (this.jobsFilter) this.loadFilterMarkup(true);
            });
        }

        if (this.proximityDropdown) {
            this.proximityDropdown.events.on('change', (event) => {
                if (this.jobsFilter) this.loadFilterMarkup(true);
                this.events.emit('selectedRadiusIndex', event.arguments[0]);
            });
        }

        if (this.jobsFilter) {
            this.propagateEvents(this.jobsFilter, 'openLoginModal');
            this.jobsFilter.events.on('closeProximityDropdown', () => this.closeProximityDropdown());
            this.jobsFilter.events.on(
                'loadFilterMarkup',
                (withUserInput) => this.loadFilterMarkup(withUserInput.arguments[0]),
            );
        }
    }

    closeProximityDropdown() {
        if (this.proximityDropdown) this.proximityDropdown.updateProps({ open: false }, true);
    }

    loadFilterMarkup(withUserInput = false) {
        let searchRequest = {};

        if (this.loadFilterMarkupRequest) this.loadFilterMarkupRequest.abort();

        if (withUserInput) {
            searchRequest = serializeJson(this.dom.el);
        } else {
            searchRequest = this.getSerializedProps();
        }

        this.loadFilterMarkupRequest = getJson(
            `${this.generateFilterMarkupUrl(searchRequest)}`,
            {},
            (response) => {
                this.loadFilterMarkupRequest = false;
                this.jobsFilter.onFilterMarkupResponse(response);
            },
        );
    }

    generateFilterMarkupUrl(searchRequest) {
        const urlParts = [];
        const urlParams = {};
        const encodedUrlParams = {};

        urlParts.push(API_ENDPOINT_FILTERS);
        if (searchRequest.keywords) {
            urlParams.keywords = searchRequest.keywords;
        }
        if (searchRequest.locations) {
            urlParams.locations = searchRequest.locations;
        }

        const url = urlParts.join('/');

        if (parseInt(searchRequest.radius, 10) >= 0) {
            urlParams.radius = parseInt(searchRequest.radius, 10);
        }
        if (searchRequest.page > 1) {
            urlParams.page = searchRequest.page;
        }
        if (searchRequest['jobFields[]']) {
            urlParams.jobFields = searchRequest['jobFields[]'];
        }
        if (searchRequest['employmentTypes[]']) {
            urlParams.employmentTypes = searchRequest['employmentTypes[]'];
        }
        if (searchRequest.homeoffice && searchRequest.homeoffice !== 0) {
            urlParams.homeoffice = searchRequest.homeoffice;
        }
        if (searchRequest['jobLevels[]']) {
            urlParams.jobLevels = searchRequest['jobLevels[]'];
        }
        if (searchRequest['salary[]'] && searchRequest['salary[]'][0] !== resetSalaryCountId) {
            urlParams.salary = searchRequest['salary[]'];
        }
        if (searchRequest.company) {
            urlParams.company = searchRequest.company;
        }

        return urlWithParams(url, urlParams, encodedUrlParams);
    }

    resetSearchInput(e, inputField) {
        const inputId = e.arguments[0];

        inputField.resetValue();
        if (inputId === 'locations' && this.proximityDropdown && !this.proximityDropdown.props.disabled) {
            this.proximityDropdown.resetOptions(true);
        }
    }

    resetProximityDropdown() {
        if (this.proximityDropdown && !this.proximityDropdown.props.disabled) {
            this.proximityDropdown.resetOptions(true);
        }
    }

    checkProximityStatus(e) {
        const query = e.arguments.length > 0 ? e.arguments[0] : null;

        if (query === null) {
            return;
        }

        if (this.proximityRequest) {
            this.proximityRequest.abort();
        }

        this.updateProps({ locations: query[0] });

        const encodedQuery = encodeURIComponent(query);
        const url = `${API_ENDPOINT_PROXIMITY_LOCATION}?query=${encodedQuery}`;

        this.proximityRequest = getJson(
            url,
            {},
            (response) => this.onProximityResponse(response),
            (error) => this.onProximityError(error),
        );
    }

    onProximityResponse(response) {
        const { isProximityDisabled } = response;

        if (isProximityDisabled) {
            this.proximityDropdown.resetOptions(isProximityDisabled);
        } else {
            this.proximityDropdown.enableDropdown();
        }
    }

    onProximityError() {
        this.proximityDropdown.resetOptions(true);
    }

    unfoldForm() {
        this.updateProps({
            collapsedSearchform: false,
        }, true);
    }

    updateHistoryItems() {
        this.keywordsAutocomplete.loadHistoryItems();
        this.locationsAutocomplete.loadHistoryItems();
    }
}
